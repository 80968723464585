<script setup>
const selectedGame = ref(0)
const jwt = useJWT()

const { public: config } = useRuntimeConfig()

const selectGame = (index) => {
  selectedGame.value = index
}
</script>

<template>
  <div class="w-full h-full relative">
    <div
      class=" bg-black rounded-t-[0.625rem] px-2 pt-2 w-full flex items-center justify-between max-w-[4.125rem] absolute -top-16 right-0">
      <div :class="{ 'bg-[#141C29]': selectedGame === 0 }" @click="selectGame(0)"
        class=" duration-200 w-[3.4375rem] h-[3.8125rem] rounded-t-[0.625rem] flex cursor-pointer">
        <img src="/images/up-or-down/updown.png" alt="" class=" m-auto">
      </div>
      <!--<div :class="{ 'bg-[#141C29]': selectedGame === 1 }" @click="selectGame(1)"
        class=" duration-200  w-[3.4375rem] h-[3.8125rem] rounded-t-[0.625rem] flex cursor-pointer">
        <img src="/images/up-or-down/coming-soojn1.png" alt="" class=" m-auto">
      </div>
      <div :class="{ 'bg-[#141C29]': selectedGame === 2 }" @click="selectGame(2)"
        class="duration-200   w-[3.4375rem] h-[3.8125rem] rounded-t-[0.625rem] flex cursor-pointer">
        <img src="/images/up-or-down/coming-soojn2.png" alt="" class=" m-auto">
      </div>
      <div :class="{ 'bg-[#141C29]': selectedGame === 3 }" @click="selectGame(3)"
        class=" duration-200  w-[3.4375rem] h-[3.8125rem] rounded-t-[0.625rem] flex cursor-pointer">
        <img src="/images/up-or-down/coming-soojn3.png" alt="" class=" m-auto">
      </div>-->
    </div>
    <div class=" w-full h-full relative" v-if="selectedGame === 0">
      <iframe class="overflow-hidden rounded-xl rounded-r-none" width="100%" height="100%"
        :src="`${ config.bitbloxUrl }?id=17&subscriptionKey=${ config.subscriptionKey }&token=${ jwt }`"
        title="" style="border: unset">
      </iframe>
    </div>
    <div class=" w-full h-full relative" v-if="selectedGame === 1">
      <img class="w-full h-full hidden sm:block" src="/images/up-or-down/chart.png" alt="">
      <img class="w-full h-full sm:hidden" src="/images/up-or-down/chart-mobile.png" alt="">
    </div>
    <div class=" w-full h-full relative" v-if="selectedGame === 2">
      <img class="w-full h-full hidden sm:block" src="/images/up-or-down/chart.png" alt="">
      <img class="w-full h-full sm:hidden" src="/images/up-or-down/chart-mobile.png" alt="">
    </div>
    <div class=" w-full h-full relative" v-if="selectedGame === 3">

      <img class="w-full h-full hidden sm:block" src="/images/up-or-down/chart.png" alt="">
      <img class="w-full h-full sm:hidden" src="/images/up-or-down/chart-mobile.png" alt="">
    </div>
  </div>
</template>