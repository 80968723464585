<template>
  <div class=" w-full pb-10 px-4">
    <div class=" max-w-[71.8125rem]  mx-auto">

      <div class=" w-full flex flex-col items-center space-y-4">
        <h1 class=" text-white text-center font-inter text-[2.5rem] md:text-[5rem] font-extrabold">How to play</h1>

        <div class=" w-full grid grid-cols-1 md:grid-cols-3 gap-7">
          <div
            class=" relative w-full bg-[#1B2128] overflow-hidden box rounded-[0.625rem] h-[21.625rem] px-8 py-8 flex flex-col items-center justify-between">
            <div class=" flex flex-col items-center space-y-2 relative z-20">
              <h2 class=" text-white font-inter font-extrabold text-xl lg:text-3xl whitespace-nowrap">Connect your wallet
              </h2>
              <p class=" text-white font-inter text-center text-[0.75rem] font-normal">
                Up & Down works with your Defi Wallet <br class=" hidden lg:block"> extension
              </p>
            </div>
            <div class=" w-full relative z-20">
              <button
                @click="$wallet.connectWallet()"
                class=" relative font-inter text-center rounded-lg w-full hover:rounded-3xl duration-200 text-white text-[1.07556rem] lg:text-[1.76606rem] font-bold bg-primary  h-[2.79063rem] sm:h-[4.58231rem] hover:shadow-primary">Connect Wallet</button>
            </div>

            <div class=" absolute left-0 bottom-0">
              <img class=" w-full h-full" src="/images/up-or-down/Ellipse1.png" alt="">
            </div>
          </div>
          <div
            class=" relative w-full bg-[#1B2128] overflow-hidden box rounded-[0.625rem] h-[21.625rem] px-8 pt-8 flex flex-col items-center justify-between">
            <div class=" flex flex-col items-center space-y-2 relative z-20">
              <h2 class=" text-white font-inter font-extrabold  text-xl lg:text-3xl whitespace-nowrap">How to bet</h2>
              <p class=" text-white font-inter text-center text-[0.75rem] font-normal leading-[200%]">
                1. Pick your Timeframe
                <br> 2. bet on Up or Down
              </p>
            </div>
            <div class=" w-full relative z-20 flex">
              <img src="/images/up-or-down/bet-up-down.png" class=" w-[12.18356rem] h-[12.32625rem] m-auto mt-9" alt="">
            </div>

            <div class=" absolute right-0 bottom-0">
              <img class=" w-full h-full" src="/images/up-or-down/Ellipse2.png" alt="">
            </div>
          </div>
          <div
            class=" relative w-full bg-[#1B2128] overflow-hidden box rounded-[0.625rem] h-[21.625rem] pl-8  pt-8 flex flex-col items-center justify-between">
            <div class=" flex flex-col items-center space-y-2 relative z-20 ">
              <h2 class=" text-white font-inter font-extrabold  text-xl lg:text-3xl whitespace-nowrap">Watch and win!</h2>
              <p class=" text-white font-inter text-center text-[0.75rem] font-normal">
                Winnings are credited to your wallet <br> immediately after the session ends
              </p>
            </div>
            <div class=" w-full relative z-20 flex">
              <img src="/images/up-or-down/stats.png" class=" ml-8 w-full h-full object-cover" alt="">
            </div>

            <div class=" absolute left-0 top-1">
              <img class=" w-full h-full" src="/images/up-or-down/Ellipse3.png" alt="">
            </div>
          </div>
          <div
            class=" md:col-span-3 relative w-full bg-[#1B2128] overflow-hidden box rounded-[0.625rem] h-[16.5rem] px-8  pt-8 flex flex-col items-center justify-between">
            <div class=" flex flex-col items-center space-y-2 relative z-20 pr-8">
              <h2 class=" text-white font-inter font-extrabold text-xl">Other Games</h2>
            </div>
            <div class=" w-full relative z-20 flex max-w-[36.5rem]">
              <img src="/images/up-or-down/coming-soojn.png" class="  w-full h-full object-cover" alt="">
            </div>

            <div class=" absolute left-0 top-0">
              <img class=" w-full h-full" src="/images/up-or-down/Ellipse4.png" alt="">
            </div>
            <div class=" absolute left-0 bottom-0 flex w-full ">
              <img src="/images/up-or-down/Ellipse5.png" class=" m-auto" alt="">
            </div>
          </div>

          <!--<div
            class=" md:col-span-3 relative w-full bg-[#1B2128] overflow-hidden box rounded-[0.625rem] h-[16.5rem] px-4  py-8 flex flex-col items-center justify-between">
            <div class=" flex flex-col items-center space-y-2 relative z-20">
              <h2 class=" text-white font-inter font-extrabold text-xl">Supported Wallets</h2>
            </div>
            <div class=" w-full relative z-20 flex max-w-[26.25rem]  items-start justify-between ">
              <div class=" flex flex-col items-center space-y-3">
                <img src="/images/up-or-down/coinbase-wallet-logo.png" alt="">
                <span class=" text-white font-inter text-[0.75rem]">Coinbase Wallet</span>
              </div>
              <div class=" flex flex-col items-center space-y-3">
                <img src="/images/up-or-down/TWT.png" alt="">
                <span class=" text-white font-inter text-[0.75rem]">TrustWallet</span>
              </div>
              <div class=" flex flex-col items-center space-y-3">
                <img src="/images/up-or-down/MetaMask_Fox.png" alt="">
                <span class=" text-white font-inter text-[0.75rem]">MetaMask Wallet</span>
              </div>
            </div>


            <div class=" absolute left-0 top-0 flex w-full ">
              <img src="/images/up-or-down/Ellipse6.png" class=" m-auto" alt="">
            </div>
          </div>-->

        </div>

        <div
          class=" w-full p-4 bg-[#1B2128] flex items-center flex-col md:flex-row space-y-4 md:space-y-0 md:justify-between rounded-[0.625rem]">
          <div class=" flex items-center space-x-2">
            <img src="/images/up-or-down/discord.png" class=" hidden md:block" alt="">
            <div class=" flex flex-col items-center md:items-start space-y-2">
              <span class=" text-white font-inter text-lg font-bold text-center">Join our Awesome Community with 24/7 Live
                support</span>
              <span class=" text-white font-inter text-[0.75rem] font-normal text-center">Share your wins, seek support,
                vote on updates & join daily promotions!</span>
            </div>
          </div>
          <a href="https://discord.gg/yQqgdtw6Ty" target="_blank"
            class="text-[0.875rem] text-white font-bold border border-white px-4 py-3 rounded-[0.1rem] hover:rounded-lg duration-200">
            Join our Discord
          </a>
      </div>
    </div>
  </div>
</div></template>

<style scoped>.box {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}</style>