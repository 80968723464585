<script setup>
const openDepositPopup = useOpenDepositPopup()
const authUser = useAuthUser()

const depositValue = ref(0.00)
</script>

<template>
  <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-[#000000] bg-opacity-80 transition-opacity"></div>

    <div class="fixed inset-0 z-50 overflow-y-auto">
      <div class="flex min-h-full h-full items-center justify-center sm:items-center ">
        <div
          class="relative transform overflow-y-auto font-inter h-full sm:rounded-[10px] w-screen sm:h-auto bg-[#132932] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[449px]">
          <button @click="openDepositPopup = false" class="cursor-pointer absolute top-[22px] right-4">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8.90561 0.674728C9.32148 0.265976 9.99575 0.265976 10.4116 0.674728C10.8275 1.08348 10.8275 1.7462 10.4116 2.15495L6.95361 5.55374L10.5097 9.04896C10.9256 9.45771 10.9256 10.1204 10.5097 10.5292C10.0939 10.9379 9.41959 10.9379 9.00371 10.5292L5.44759 7.03397L1.89147 10.5292C1.47559 10.9379 0.801328 10.9379 0.385453 10.5292C-0.0304215 10.1204 -0.0304212 9.45771 0.385454 9.04896L3.94157 5.55374L0.483557 2.15495C0.0676822 1.7462 0.0676822 1.08348 0.483557 0.674728C0.899432 0.265976 1.5737 0.265976 1.98957 0.674728L5.44759 4.07352L8.90561 0.674728Z"
                fill="white" fill-opacity="0.5" />
            </svg>
          </button>

          <div class="px-4 py-4 flex gap-x-4">
            <img src="/images/user.png" loading="lazy" alt="Profile icon">
            <span>{{ authUser.email }}</span>
          </div>

          <div class="mt-2 px-12">
            <div class="bg-[#0D2129] px-4 py-4 rounded-[10px]">
              <div class="font-inter text-sm font-medium flex justify-between gap-x-5 mb-2">
                <span>Wallet balance</span>
                <span class="font-bold">$ {{ Number($wallet.USDCBalance).toFixed(2) }}</span>
              </div>

              <hr>

              <div class="font-inter text-sm font-medium flex justify-between gap-x-5 mt-2">
                <span>In-game balance</span>
                <span class="font-bold">$ {{ Number(authUser.balance).toFixed(2) }}</span>
              </div>
            </div>
          </div>

          <div class="mt-6 px-16 flex justify-center flex-col">
            <label for="deposit-amount" class="text-xs relative">
              <span class="select-none">Amount</span>

              <input
                type="text"
                id="deposit-amount"
                @click="depositValue <= 0 ? depositValue = '' : null"
                class="mt-1 w-full bg-white rounded-lg h-[2.4rem] px-2 outline-none text-black text-sm" v-model="depositValue" v-maska data-maska="0.99" data-maska-tokens="0:\d:multiple|9:\d:optional"
                :disabled="$wallet.withdrawTxt || $wallet.depositTxt"
              >

              <img src="/images/usdc.png" loading="lazy" alt="USDC logo" class="absolute top-[29px] right-3">
            </label>
          </div>

          <div class="mt-4 mb-4 px-16 text-xs text-green-700">
            {{ $wallet.depositTxt || $wallet.withdrawTxt }}
          </div>

          <div class="mt-6 mb-8 px-12 flex gap-x-8">
            <button
            @click="$wallet.withdraw(depositValue)"
              class="text-white font-inter font-bold flex justify-center items-center text-[0.875rem] border-2 border-white w-[11.25rem] h-[3.125rem] rounded-[0.1875rem] hover:rounded-lg duration-200 hover:border-[#d4145a] hover:bg-secondary disabled:opacity-50"
              :disabled="$wallet.withdrawTxt || $wallet.depositTxt"
            >Withdraw</button>

            <button
              @click="$wallet.deposit(depositValue)"
              class="text-white font-inter font-bold flex justify-center items-center text-[0.875rem] bg-primary w-[11.25rem] h-[3.125rem] rounded-[0.1875rem] hover:rounded-lg duration-200 hover:shadow-primary disabled:opacity-50"
              :disabled="$wallet.depositTxt || $wallet.withdrawTxt"
            >Deposit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>