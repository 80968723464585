<template>
   <footer class="w-full pt-16">
     <div class="w-full relative  ">
        <img src="/images/up-or-down/Generative.webp" class="w-full h-[39.9375rem] xl:h-auto object-cover" alt="">
        <div class="w-full h-full flex flex-col items-center sm:items-start top-0 left-0 absolute justify-between xl:space-y-20 px-4 py-8  sm:px-14 sm:py-28">
            <div class="w-full flex flex-col items-center sm:items-start space-y-4">
                <h1 class="text-white font-inter text-[2.5rem] xl:text-[5rem] font-extrabold text-center">Feeling Lucky?</h1>

                <span class="text-white font-extrabold text-[1.25rem] xl:text-[1.875rem] font-inter text-center">Come and play on our Crypto Casino.</span>

                <div class="flex items-center sm:flex-row flex-col space-y-4 sm:space-y-0 sm:space-x-4 pt-8">
                  <a href="https://coingames.fun/en" target="_blank" class="text-white font-inter font-bold flex justify-center items-center text-[0.875rem] bg-primary w-[11.25rem] h-[3.125rem] rounded-[0.1875rem] hover:rounded-lg duration-200 hover:shadow-primary">Coingames Casino</a>
                  <a href="https://coingames.fun/en/sports" target="_blank" class="text-white font-inter font-bold flex justify-center items-center text-[0.875rem] border border-white w-[11.25rem] h-[3.125rem] rounded-[0.1875rem] hover:rounded-lg duration-200">Coingames SportsBook</a>
                </div>
            </div>

            <div class="flex flex-col items-center sm:items-start space-y-8">
                <img src="/images/logo.svg" alt="">
                <div class="flex items-center space-x-4">
                  <a href="https://twitter.com/CoinGames_Offic" target="_blank" aria-label="X">
                    <UIIconsTwitter fill="#FFFFFF" class="hover:bg-primary transition ease-linear delay-150 duration-300 h-[40px] w-[40px] bg-[#112A34] rounded-full p-1" />
                  </a>
                  <a href="https://www.facebook.com/CoinGames-103913795554836" target="_blank" aria-label="Facebook">
                    <UIIconsFacebook fill="#112A34" class="hover:fill-primary transition ease-linear delay-150 duration-300 h-[40px] w-[40px]" />
                  </a>
                  <a href="https://www.instagram.com/coin_games_official/" target="_blank" aria-label="Instagram">
                    <UIIconsInstagram fill="#112A34" class="hover:fill-primary transition ease-linear delay-150 duration-300 h-[40px] w-[40px]" />
                  </a>
                  <a href="https://discord.gg/yQqgdtw6Ty" target="_blank" aria-label="Discord">
                    <img width="40" height="40" loading="lazy" src="/images/Discord.svg" class="bg-[#112A34] rounded-full p-1 hover:bg-primary transition ease-linear delay-150 duration-300 h-[40px] w-[40px]" alt="discord" />
                  </a>
                </div>
            </div>
        </div>
    </div>
   </footer>
</template>