<template>
  <section class="w-full h-full relative">
    <!--  -->
    <div class="w-full relative">
      <img src="/images/up-or-down/hero.webp"
        class="w-full h-[50.9375rem] sm:h-[51.3125rem] lg:h-[69.313rem] object-cover" alt="hero">
      <div class=" w-full h-full absolute top-[9rem] sm:top-[8rem] lg:top-[8rem] left-0">
        <div class="max-w-[32.90744rem] mx-auto flex flex-col items-center space-y-0 sm:space-y-5">
          <p
            class="text-white font-inter font-extrabold text-[2.25538rem] sm:text-[5rem] text-center leading-[2rem] sm:leading-[4.6rem] relative z-20  ">
            <span>Up</span> <span class="text-[1.12769rem] sm:text-[2.5rem]">or</span>
            <br>
            <span class="pl-5">Down</span>
          </p>
        </div>

        <div class="w-full flex px-4 pt-20 md:pt-10 lg:pt-20 relative z-20 ">
          <div
            class="m-auto w-full rounded-xl lg:max-w-[71.8125rem] max-w-[37.52375rem] md:max-w-[58.18581rem] h-[51.24806rem]">
            <UIGames />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>