<template>
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="25" height="26" rx="12.5" />
    <path
      d="M9.24863 13.4658H10.7559V19.919C10.7559 20.0464 10.8551 20.1496 10.9776 20.1496H13.5332C13.6557 20.1496 13.7549 20.0464 13.7549 19.919V13.4962H15.4876C15.6003 13.4962 15.6951 13.4083 15.7079 13.2919L15.9711 10.9161C15.9783 10.8508 15.9584 10.7853 15.9164 10.7363C15.8743 10.6872 15.814 10.6591 15.7508 10.6591H13.755V9.16988C13.755 8.72096 13.9875 8.49331 14.4459 8.49331C14.5112 8.49331 15.7508 8.49331 15.7508 8.49331C15.8733 8.49331 15.9726 8.39003 15.9726 8.26267V6.08196C15.9726 5.95455 15.8733 5.85132 15.7508 5.85132H13.9525C13.9398 5.85067 13.9116 5.84961 13.8701 5.84961C13.5581 5.84961 12.4734 5.91331 11.6167 6.73303C10.6674 7.6414 10.7994 8.72903 10.8309 8.91761V10.6591H9.24863C9.12612 10.6591 9.02686 10.7623 9.02686 10.8897V13.2351C9.02686 13.3625 9.12612 13.4658 9.24863 13.4658Z"
      fill="white"
    />
  </svg>
</template>