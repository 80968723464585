<script setup>
const openRegistrationPopup = useOpenRegistrationPopup()
const openDepositPopup = useOpenDepositPopup()
</script>

<template>
  <div class=" w-full ">
      <PartialsSectionsFirstSection />
     <div class=" w-full pt-[20rem]  sm:pt-[24rem] lg:pt-[8rem]">
       <PartialsSectionsSecondSection />
     </div>
     <PartialsSectionsThirdSection />

     <UISignup v-if="openRegistrationPopup" />
     <UIDeposit v-if="openDepositPopup" />
  </div>
</template>